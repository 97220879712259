/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable jsx-a11y/aria-role */
import { Component } from 'react';
import Swiper from 'react-id-swiper/lib/custom';
import { Link } from '@dx-ui/osc-link';
import type SwiperInstance from 'swiper';
import Image from 'next/image';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';
import { Container } from '../../hocs/withLayout';
import { icons } from '../../utils/constants/home';
import { formatLabel } from '../../utils/helpers/metrics';
import type { Props, RotatingTilesText } from './RotatingTiles';
import { assetPath, handleTransitionEnd, initSwiper } from './RotatingTiles.helpers';
import {
  Arrow,
  CarouselWrapper,
  Content,
  ContentDescription,
  ContentHeader,
  Header,
  Item,
  Li,
  NavArrows,
  OuterWrapper,
  Pager,
  SubText,
  Text,
  Wrapper,
} from './RotatingTiles.styles';
import type { BrandRotatingTilesItem } from '@dx-ui/queries-dx-guests-ui';

const defaultOptions = {
  ContainerEl: 'section',
  WrapperEl: 'ul',
  a11y: false,
  slideToClickedSlide: true,
  centeredSlides: true,
  loop: true,
  slidesPerView: 5,
  threshold: 1000,
  watchSlidesVisibility: true,
};

type CarouselProps = Props & {
  text: RotatingTilesText;
};

type CarouselState = {
  realIndex: number;
};

/* eslint-disable no-return-assign */
export default class CarouselPure extends Component<CarouselProps, CarouselState> {
  public state = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    realIndex: Math.floor(this.props.items.length / 2),
  };

  private swiper: SwiperInstance | null = null;

  private desktopOptions = {
    ...defaultOptions,
    initialSlide: this.state.realIndex,
    on: {
      init: initSwiper,
      transitionEnd: () => this.handleTransitionEnd(),
    },
  };

  private handleTransitionEnd = () => {
    if (this.swiper) {
      if (this.swiper.slides) {
        handleTransitionEnd(Array.from(this.swiper.slides));
      }
      if (!Number.isNaN(this.swiper.realIndex)) {
        this.setState({ realIndex: this.swiper.realIndex });
      }
    }
  };

  private slideNext = () => {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  };

  private slidePrev = () => {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  };

  private pagerText = () => {
    const { text, items } = this.props;
    const { realIndex } = this.state;
    const difference = !isNaN(realIndex - 2) && realIndex < items.length ? realIndex - 2 : 0;
    const current = ((difference < 0 ? items.length + difference : difference) + 1).toString();
    const total = items.length.toString();
    return `${current} ${text.of} ${total}`;
  };

  private slideComponent = ({
    idx,
    headline,
    link,
    shortDescription,
  }: BrandRotatingTilesItem & { idx: number }) => {
    const icon = icons[idx] as string;
    return (
      <Li key={idx}>
        <Item className="dx-item" data-e2e={`perksItem${idx + 1}`}>
          <Pager
            aria-hidden="true"
            className="dx-pager"
            data-e2e={`perksPager${idx + 1}`}
            language={this.props.language}
          >
            <Image
              alt=""
              src={assetPath(icon)}
              data-e2e={`perksIcon${idx + 1}`}
              height={100}
              width={100}
              className={cx('size-14', {
                'translate-x-1': icon.includes('phone'),
                'h-16': icon.includes('price'),
              })}
            />
          </Pager>
          <Content className="dx-content" data-e2e={`perksContent${idx + 1}`}>
            <ContentHeader
              tabIndex={-1}
              className="dx-content-header"
              data-e2e={`perksContentHeader${idx + 1}`}
              outline={false}
            >
              {headline}
            </ContentHeader>
            <ContentDescription data-e2e={`perksContentDesc${idx + 1}`}>
              {shortDescription}
            </ContentDescription>
            {link && link.label && link.url && (
              <ContentDescription>
                <Link
                  url={link.url.replace('__LANG__', this.props.language || 'en')}
                  data-testid={`perksContentLink${idx + 1}`}
                  isNewWindow={Boolean(link.isNewWindow)}
                  {...(link.adaDescription ? { title: link.adaDescription } : {})}
                  cidParams={{
                    campaignId: link?.campaignId as string,
                    componentName: 'RotatingTiles',
                  }}
                  className="text-primary hover:text-primary-alt pointer-events-auto break-words no-underline"
                >
                  {link.label}
                </Link>
              </ContentDescription>
            )}
          </Content>
        </Item>
      </Li>
    );
  };

  private handlePagerClick = (nextOrPrev: string) => () => {
    if (nextOrPrev) {
      nextOrPrev.toLowerCase().includes('prev') ? this.slidePrev() : this.slideNext();
    }
  };

  private renderCarousel = () => {
    const { text } = this.props;
    const pagerText = this.pagerText();

    return (
      <OuterWrapper>
        <Swiper
          {...this.desktopOptions}
          ref={(node: HTMLElement & { swiper: SwiperInstance }) =>
            node && node.swiper && (this.swiper = node.swiper)
          }
        >
          {this.props.items.map((slide, idx) => this.slideComponent({ ...slide, idx }))}
        </Swiper>
        <NavArrows>
          <Arrow
            isLeft={true}
            id="perksPrevArrow"
            aria-label={text.prev}
            onClick={this.handlePagerClick('perksPrevArrow')}
            language={this.props.language}
          >
            <Icon name="arrowhead-left" className="fill-bg-alt" size="2xl" />
          </Arrow>
          <Text aria-hidden="true" data-testid="pagerText">
            {pagerText}
          </Text>
          <span className="sr-only">
            {pagerText}
            {text.benefits}
          </span>
          <Arrow
            id="perksNextArrow"
            aria-label={text.next}
            onClick={this.handlePagerClick('perksNextArrow')}
            language={this.props.language || 'en'}
          >
            <Icon name="arrowhead-right" className="fill-bg-alt" size="2xl" />
          </Arrow>
        </NavArrows>
      </OuterWrapper>
    );
  };

  public componentDidUpdate(_prevProps: CarouselProps, _prevState: CarouselState) {
    const { items, metrics } = this.props;
    const currentIndex = this.state.realIndex;
    const perk = items && items[currentIndex];
    if (metrics && perk && perk.headline) {
      const pageDetail = formatLabel(perk.headline);
      if (pageDetail) {
        const eventAction = `${pageDetail}initiated`;
        metrics.setEventAction([{ eventAction }]);
        metrics.trackEvent(eventAction);
        metrics.setPageDetails([{ pageDetail }]);
        metrics.trackPageDetails();
      }
    }
  }

  public render() {
    const { headline, description } = this.props;
    return (
      <Wrapper data-testid="rotatingTiles">
        <Container>
          <Header className="font-headline font-semibold" data-e2e="perksHeading">
            {headline}
          </Header>
          <SubText data-e2e="perksSubText">{description}</SubText>
        </Container>
        <CarouselWrapper language={this.props.language}>{this.renderCarousel()}</CarouselWrapper>
      </Wrapper>
    );
  }
}
