/* eslint-disable import/no-cycle */
import type * as React from 'react';
import type { Metrics } from '../../config';
import { MetricsContext } from '../../config/metrics';
import { Translation } from 'next-i18next';
import Carousel from './RotatingTiles.pure';
import RotatingTilesMobile from './RotatingTilesMobile';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { BrandRotatingTiles } from '@dx-ui/queries-dx-guests-ui';

export type Props = {
  metrics: Metrics;
  language?: SupportedLanguage;
} & BrandRotatingTiles;

export type RotatingTilesText = {
  benefits: string;
  of: string;
  next: string;
  prev: string;
  newTab: string;
};

const RotatingTiles: React.FC<React.PropsWithChildren<Props>> = (props) => {
  if (!props.items) {
    return null;
  }
  // todo: Remove RotatingTilesMobile once we start tailwind work and find a solution for device detection for responsive design
  return (
    <MetricsContext.Consumer>
      {({ metrics }) => (
        <Translation>
          {(t) => {
            const text = t('rotatingTiles', { returnObjects: true });
            return (
              <>
                <div className="hidden lg:block">
                  <Carousel {...props} text={text} metrics={metrics} />
                </div>
                <div className="block lg:hidden">
                  <RotatingTilesMobile {...props} text={text} metrics={metrics} />
                </div>
              </>
            );
          }}
        </Translation>
      )}
    </MetricsContext.Consumer>
  );
};

export default RotatingTiles;
